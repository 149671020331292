.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}
